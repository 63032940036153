import React, { useState } from "react";
import "../Css/LearningOutcome.css";
import H1 from '../assets/H1.webp'
import H2 from '../assets/H2.webp'
import H3 from '../assets/H3.webp'

const LearningOutcome = () => {
  return (
    <div className="learning-outcome-section">
      <h2 className="section-heading">LEARNING OUTCOME

      </h2>
      <div className="card-container">
        <OutcomeCard
          imgSrc= {H1}
          title="Industry Curriculum"
          description="Unlock your potential with our industry-standard curriculum and expert trainers. Gain the skills and knowledge you need to excel in your field. Our trainers bring real-world experience and expertise to help you stay ahead of the competition. Start your journey towards success today."
        />
          <OutcomeCard
          imgSrc= {H2}
          title="Global Certification Guidance"
          description="Achieve global certification with confidence and ease. Our comprehensive guidance program ensures you have the knowledge and skills to pass your certification exam on the first try. With our proven track record of success, you can trust us to guide you every step of the way towards achieving your professional goals. Start your journey today with our passing guarantee!"
        />
        <OutcomeCard
          imgSrc= {H3}
          title="Projects"
          description="Experience real-world projects and gain practical skills with our comprehensive training programs. Our hands-on approach ensures that you’ll be ready to tackle any challenge in your field. Enroll today and take your career to the next level.
"
        />
      
      </div>
    </div>
  );
};

const OutcomeCard = ({ imgSrc, title, description }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={`outcome-card ${hovered ? "hovered" : ""}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="card-img-wrapper">
        <img src={imgSrc} alt={title} className={`card-img ${hovered ? "color" : ""}`} />
      </div>
      <div className="card-content">
        <h3 className="card-title">{title}</h3>
        {hovered && <p className="card-description">{description}</p>}
      </div>
    </div>
  );
};

export default LearningOutcome;
