import React from "react";
import "../Css/Cart.css"; // Ensure CSS is imported correctly
import C1 from "../assets/C1.webp"; // Ensure path is correct
import C2 from "../assets/C2.webp";
import C3 from "../assets/C4.webp";
import C4 from "../assets/C5.webp";
import C5 from "../assets/C3.webp";
import C6 from "../assets/C6.webp";
import C7 from "../assets/C7.webp";
import C8 from "../assets/C8.webp";
import C9 from "../assets/C9.webp";
import C10 from "../assets/C10.webp";
import C11 from "../assets/C11.webp";
import C12 from "../assets/C12.webp";

const CartComponent = () => {
  const cartsData = [
    {
      heading: "SALESFORCE",
      info: "Master Salesforce by completing Our Training Career Advancement Program.",
      image: C7,
      link: "/salesforce-training",
    },
    {
      heading: "DATA ANALYTICS",
      info: "Accelerate Your Career with Data Analytics Training and Certification program.",
      image: C11,
      link: "/best-data-analytics-training",
    },
    {
      heading: "DATA SCIENCE",
      info: "Become a Certified Data Scientist with Our Training courses.",
      image: C10,
      link: "/best-data-science-training",
    },
    {
      heading: "FULL STACK DEVELOPER",
      info: "Unlock limitless opportunities with Full Stack Development mastery.",
      image: C3,
      link: "/full-stack-developer",
    },

    {
      heading: "DEVOPS",
      info: "Master DevOps with AWS and Linux Training: Unlock Your Full Potential.",
      image: C2,
      link: "/best-devops-training",
    },
    {
      heading: "ML",
      info: "Transform Your Future: Attain ML Certification through Our Training Courses.",
      image: C1,
      link: "/best-machine-learning-training",
    },
    {
      heading: "AWS",
      info: "Empower Your Expertise: AWS with Our Comprehensive Training Courses.",
      image: C4,
      link: "/best-aws-training",
    },
    {
      heading: "PYTHON",
      info: "Empower Your Future with Python Training Excellence.",
      image: C5,
      link: "/python",
    },
    {
      heading: "BUSINESS ANALYTICS",
      info: "Unlock the Power of Data with Business Analytics Training courses.",
      image: C6,
      link: "/best-business-analytics-training",
    },
   
    {
      heading: "AI & ML",
      info: "Empower Your Future: Become a Certified AI and ML Expert with Our Training Courses.",
      image: C8,
      link: "/best-ai-ml-training",
    },
    {
      heading: "SHARE MARKET",
      info: "Unlock the potential of your investments with expert market insights.",
      image: C9,
      link: "/sharemarket",
    },

    {
      heading: "TALLY",
      info: "Mastering Tally: Your Path to Financial Expertise.",
      image: C12,
      link: "/tally",
    },
  ];

  return (
    <div className="cart-section">
      {/* Common Heading and Subheading */}
      <div className="cart-section-header">
        <h1>OUR TRAINING COURSES</h1>
        <p>
          Be certified by global certifications and increase your understanding
        </p>
      </div>

      {/* Cart Grid */}
      <div className="cart-container">
        {cartsData.map((cart, index) => (
          <div className="cart" key={index}>
            <div
              className="cart-image"
              style={{ backgroundImage: `url(${cart.image})` }}
            >
              <div className="hover-info">
                <p>{cart.info}</p>
                <div className="know-more-underline">
                  <a href={cart.link}>Know More</a>
                </div>
              </div>
            </div>
            <div className="cart-content">
              <h3>{cart.heading}</h3>
              <button className="enquire-btn">
                <a href="/contact">Enquire Now </a>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CartComponent;
