// src/components/Hero.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'; // Choose an icon that you like
import '../Css/Hero.css';

const Hero = () => {
    return (
        <section className="hero-section">
            <div className="hero-content">
                <h1>LEARN WITH SAPALOGY FOR BRIGHT FUTURE</h1>
                <ul>
                    <li><FontAwesomeIcon icon={faCheckCircle} /> Expert Instructors</li>
                    <li><FontAwesomeIcon icon={faCheckCircle} /> Project-based learning</li>
                    <li><FontAwesomeIcon icon={faCheckCircle} /> Internship Opportunities</li>
                    <li><FontAwesomeIcon icon={faCheckCircle} /> Certification programs</li>
                </ul>
            </div>
        </section>
    );
};

export default Hero;
