import React from 'react'
import Hero from '../Components/Hero'
import Cart from '../Components/Cart'
import WhyUs from '../Components/WhyUs'
import LearningOutcome from '../Components/LearningOutcome'
import Slider from '../Components/Slider'
import WhatMakesUsDifferent from '../Components/WhatMakesUsDifferent'
import ContactForm from './Contact'
const Home = () => {
  return (
    <div>
      <Hero/>
      <Cart/>
      <WhatMakesUsDifferent/>
      <WhyUs/>
      <LearningOutcome/>
      <Slider/>
      <ContactForm/>
    </div>
  )
}

export default Home
