import React, { useEffect, useRef } from 'react';
import '../Css/DevOps.css';
import certificateImg from '../assets/certificate.webp';
import Contact from '../pages/Contact'
import IMG from '../assets/Work.webp'
import Roadmap from '../Components/Roadmap';
import WhatsDevOps from '../Components/WhatsDevOps'
import PageForm from '../Components/PageForm';
import Syllabus from '../Components/Syllabus';
import KeyFeatures from '../Components/KeyFeatures';
import Faq from '../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
// project slider image


import rev1 from '../assets/Reviews/Tally/1.png';
import rev2 from '../assets/Reviews/Tally/2.png';
import rev3 from '../assets/Reviews/Tally/3.png';
import rev4 from '../assets/Reviews/Tally/4.png';
import RegularSlider from '../Components/RegularSlider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle } from '@fortawesome/free-solid-svg-icons';


const Tally = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);


  // useEffect(() => {
  //   // Function to create the YouTube player
  //   const createPlayer = () => {
  //     if (window.YT && window.YT.Player) {
  //       playerRef.current = new window.YT.Player(videoRef.current, {
  //         videoId: 'i3HdVH6gdfE', // Updated YouTube video ID
  //         events: {
  //           onReady: (event) => {
  //             event.target.mute(); // Mute the video to allow autoplay
  //             // event.target.playVideo();
  //           },
  //         },
  //         playerVars: {
  //           loop: 1,
  //           controls: 0, // Hide video controls
  //           modestbranding: 1, // Minimal YouTube branding
  //           playlist: 'i3HdVH6gdfE', // Required for looping
  //         },
  //       });
  //     }
  //   };

  //   // Load YouTube IFrame API if it's not already loaded
  //   if (!window.YT) {
  //     const tag = document.createElement('script');
  //     tag.src = 'https://www.youtube.com/iframe_api';
  //     const firstScriptTag = document.getElementsByTagName('script')[0];
  //     firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //     // Set up callback for when the API is ready
  //     window.onYouTubeIframeAPIReady = () => {
  //       createPlayer();
  //     };
  //   } else {
  //     createPlayer();
  //   }
  // }, []);


  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What is Tally?',
        'Tally course',
        'Tally certification',
        'Tally jobs in india',
        'Tally jobs in nagpur',
      ]
    },
    {
      title: 'Understanding Tally Basics',
      points: [
        'Familiarize with Tally’s interface and navigation.',
        'Learn to create and manage company data.',
        'Explore basic accounting concepts like ledger entries and voucher creation.',
      ]
    },
    {
      title: 'Mastering Tally Features',
      points: [
        'Dive into advanced features like multiple currencies and bank reconciliation.',
        'Gain proficiency in inventory management and cost centers.',
        'Learn to customize reports and generate insightful financial statements.',
      ]
    },
    {
      title: 'Advanced Financial Analysis',
      points: [
        "Understand financial ratios and analysis techniques.",
        "Interpret balance sheets, profit and loss statements, and cash flow reports.",
        "Utilize Tally for budgeting, forecasting, and decision-making.",
      ]
    },
    {
      title: 'GST and Tax Compliance',
      points: [
        'Get hands-on experience in GST invoicing and tax calculations.',
        'Learn to generate GST returns and comply with regulatory requirements.',
        'Understand Tally’s role in tax audits and compliance reporting.',
      ]
    },
    {
      title: 'Efficient Inventory Management',
      points: [
        "Explore inventory valuation methods and stock management.",
        "Learn to track stock movements, reorder levels, and batch-wise details.",
        "Optimize inventory control using Tally’s features for better business efficiency.",
      ]
    },
    {
      title: 'Practical Applications and Projects',
      points: [
        'Apply Tally skills to real-world scenarios and business cases.',
        'Collaborate on projects involving financial analysis and reporting.',
        'Prepare for Tally certification exams and showcase your expertise. ',
      ]
    },
    {
      title: 'Resume preparation',
      points: [
        'Include keywords.',
        'How to prepare resume.',
        'How to prepare resume for freshers.',
        'Show impact.',
        'Include soft skills.',
        'Be unique.'
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Basics of accounting',
      subpoints: [
        "Introduction",
        "Types of Accounts",
        'Accounting Principles or concepts',
        'Mode of Accounting',
        'Rules of Accounting',
        'Double-entry system of bookeeping',
      ],
    },
    {
      title: 'Fundamental of Tally. ERP9',
      subpoints: [
        'Company Features',
        'Configuration',
        'Getting functions with Tally.ERP9',
        'Creation / setting up of Company in Tally.ERP9',
      ],
    },
    {
      title: 'Accounting masters in Tally.ERP9',
      subpoints: [
        'Chart of Groups',
        'Groups',
        'Multiple Groups',
        'Ledgers',
        'Multiple Ledgers',
      ],
    },
    {
      title: 'Inverntory masters in Tally.ERP9',
      subpoints: [
        'Stock Groups',
        'Multiple Stock Groups',
        'Stock Categories',
        'Multiple Stock Categories',
        'Units of Measure',
        'Stock Items',
      ],
    },
    {
      title: 'Vouchers entries in Tally.ERP9',
      subpoints: [
        'Introduction',
        'Types of Vouchers',
        'Chart of Vouchers',
        'Accounting Vouchers',
        'Inventory Vouchers',
        'Invoicing',
      ],
    },
    {
      title: 'Advance accounting in Tally.ERP9',
      subpoints: [
        'Bill-wise details',
        'Cost centers and Cost Categories',
        'Multiple currencies',
        'Interest calculations',
        'Budget and controls',
        'Scenario management',
        'Bank Reconciliation',
      ],
    },
    {
      title: 'Advance inventory in Tally.ERP9',
      subpoints: [
        'Order Processing',
        'Recorder Levels',
        'Batch-wise details',
        'Bill of Materials',
        'Batch-Wise Details',
        'Different Actual and Billed Quantities',
        'Price Lists',
        'Zero-Valued Entries',
        'Additional cost details',
        'POS',
      ],
    },
    {
      title: 'Taxes in Tally.ERP9',
      subpoints: [
        "TDS",
        "TDS Reports",
        "TDS Online Payment",
        "TDS Returns filing",
        "TDS Certificate issuing",
        "26AS Reconciliation",
        "TCS",
        "TCS Reports",
        "GST",
        "GST Returns",
        "EPF",
        "ESIC",
        "Professional Tax",
      ],
    },
    {
      title: 'Technological advantages in Tally.ERP9',
      subpoints: [
        'Tally vault',
        'Security controls',
        'Tally Audit',
        'Backup and restore',
        'Split company data',
        'Import and export of data',
        'Printing Reports and Cheques',
        'Create a Company Logo',
      ],
    },
    {
      title: 'Payroll accounting in Tally.ERP9',
      subpoints: [
        'Employee Creation',
        'Salary Define',
        'Employee Attendance Register',
        'Pay Heads Creation',
        'Salary Report',
      ],
    },
    {
      title: 'Payroll accounting in Tally.ERP9',
      subpoints: [
        'Financial Statements',
        'Trading Account',
        'Profit & Loss Account',
        'Balance Sheet',
        'Accounts Books and Reports',
        'Inventory Books and Reports',
        'Exception Reports',
        'Statutory Reports',
        'Payroll Reports',
        'Trail balance',
        'Day Book',
        'List of Accounts',
        'Stock Summary',
        'Outstanding Statement',
      ],
    },
  ];

  const keyFeatures = [
    "63+ Hours course duration",
    "100% Job oriented Training",
    "Industry expert faculties",
    "Free demo class available",
    "Completed 358+ Batches",
    "Certifaction guidance"
  ];

  const faqs = [
    {
      question: "What is Tally?",
      answer: "Tally is accounting software used by businesses to manage finances, inventory and tax compliance, including GST. It’s user-friendly and scalable for small to large enterprises.",
    },
    {
      question: "What is Tally ERP 9?",
      answer: "Tally ERP 9 is a comprehensive business management software that helps in accounting, inventory management, payroll, and compliance. It’s widely used by businesses to streamline their financial operations.",
    },
    {
      question: "How can Tally benefit my business?",
      answer: "Tally simplifies accounting tasks, automates processes, provides real-time insights into finances, ensures compliance with tax regulations, and facilitates efficient inventory management, leading to improved business efficiency and decision-making.",
    },
    {
      question: "Is Tally easy to learn for beginners?",
      answer: "Yes, Tally is designed with a user-friendly interface and intuitive features, making it relatively easy for beginners to learn. With proper training and practice, users can quickly grasp the basics and advance to more complex functions.",
    },
    {
      question: "Can Tally handle GST compliance?",
      answer: "Absolutely. Tally ERP 9 is GST-ready and equipped to handle all aspects of GST compliance, including GST invoicing, tax calculations, generating GST returns, and staying updated with GST regulations and changes.",
    },
    {
      question: "Does Tally support multiple currencies?",
      answer: "Yes, Tally supports multiple currencies, making it ideal for businesses operating in international markets. Users can easily manage transactions in different currencies, track exchange rates, and generate accurate financial reports.",
    },
    {
      question: "Can I access Tally data remotely?",
      answer: "Yes, Tally offers remote access capabilities, allowing users to access and work on Tally data from anywhere using secure internet connectivity. This feature is beneficial for businesses with multiple locations or remote teams.",
    },
    {
      question: "Is Tally suitable for small businesses?",
      answer: "Yes, Tally is well-suited for small businesses as it offers cost-effective solutions for accounting, inventory management, payroll, and compliance. It can scale with the business’s growth and adapt to changing requirements.",
    },
    {
      question: "What kind of support does Tally provide?",
      answer: "Tally provides extensive support through documentation, tutorials, forums, and help desks. Users can also avail of training programs and certification courses to enhance their Tally skills and knowledge.",
    },
  ];

  const whatsData = [
    {
      title: 'What is Tally?',
      points: [
        'Tally is a popular accounting software used for financial management.',
        'It helps businesses maintain their books, manage inventory, and generate financial reports.',
        'Tally simplifies GST and tax compliance, making it a valuable tool for businesses of all sizes.',
        'Sapalogy cover all the Tally skills to get you hired in 2024.',
        'Sapalogy Training provides Tally Class in offline and online mode. Support with real time Tally project based training.',
        'IT background, non IT background, freshers, experience can start their career in Tally irrespective of their background.',
        'Sapalogy is the Best Tally classes in Nagpur with the 100% job opportunities.',
      ]
    }
  ]

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

 
  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },

  ]


  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Best Tally Training</h1>
          <p>
            Tally Class in Nagpur by Sapalogy training offers you the Best Tally Classes in Nagpur for freshers and experience candidate in Nagpur, with expert guidance and 100% guaranteed placement assistance. Our Tally course covers every technical topics in details and the student is left with real-time hand on experience.
          </p>
          <p>
            Our practical, Job-oriented Tally Class program will not only provide you with a certificate but also with knowledge equivalent to around 20+ years of field experience. We value your time as much as over. Hence we invented to train you in Tally in just 2 months
          </p>
          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} /> Advanced features</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} />Financial analysis</li>
            <li><FontAwesomeIcon icon={faBriefcase} />Tax compliance</li>
            <li><FontAwesomeIcon icon={faCheckCircle} />Inventory management</li>
            <li><FontAwesomeIcon icon={faCheckCircle} />Interview preparation</li>
            <li><FontAwesomeIcon icon={faCheckCircle} />Case studies</li>
            <li><FontAwesomeIcon icon={faCheckCircle} />5+ Capstone project</li>
            <li><FontAwesomeIcon icon={faCheckCircle} />Industry level projects</li>
            <li><FontAwesomeIcon icon={faCheckCircle} />With 100% job opportunities guaranteed program</li>
          </ul>
        </div>

        <PageForm />
      </div>
      <WhatsDevOps whatsData={whatsData} />
      {/* <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div> */}
      <Roadmap heading="Roadmap to Learn Tally" roadmapData={roadmapData} />
      <Syllabus heading="Tally Training Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="Tally Training" keyFeatures={keyFeatures} />

      <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for Tally Training</h2>
        <p>
          Sapalogy provides flexible timings to all our students. Here is the DevOps Training Class Schedule in our branches. If this schedule doesn’t match your availability, please let us know. We will try to arrange appropriate timings based on your flexible timings.
        </p>
        <div className="batch-schedule-table-wrapper">
        <table className="batch-schedule-table">
          <thead>
            <tr>
              <th>Course</th>
              <th>Batch Time</th>
              <th>Offline</th>
              <th>Online</th>
              <th>Enquire Now</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Tally</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            {/* <tr>
              <td>SAP MM</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            <tr>
              <td>SAP FICO</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr> */}
          </tbody>
        </table>
      </div></div>

      <section className="devops-certification-section">
        <h2 className="certification-heading">Tally certification</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
              <li>The Tally certification offered by Sapalogy Training will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>Sapalogy provides comprehensive guidance for your Tally certification, ensuring a 100% passing guarantee in examinations such as Tally Certification, Tally Platform Certification, and various other global exams.
              </li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>



      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">Tally Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}

      <Faq Faqs={faqs} />

      <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div>


      <div className="alumni-section">
        <h1>Our Alumni Works At</h1>
        <div className="full-screen-image">
          <img src={IMG} alt="Full Screen Image" />
        </div>
      </div>
      <Contact />

    </div>
  );
};

export default Tally;