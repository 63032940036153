import React, { useState, useEffect } from 'react';
import '../Css/ContactForm.css';
import { useForm } from 'react-hook-form';

function ContactForm() {
  const [isOpen, setIsOpen] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  // Show the form after 7 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 7000);
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmit = async (data) => {
    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          access_key: "fabb3cfb-5cb8-4f83-81ae-b1c5caf0797a", // Replace with your Web3Forms access key
          name: data.name,
          phone: data.phone, // Changed from email to phone
          message: data.message,
          recipient_email: "shivanihiware77@gmail.com",
        }),
      });
      const result = await response.json();
      if (result.success) {
        console.log("Form submitted successfully");
        reset(); // Clears the form
      } else {
        console.error("Form submission error:", result);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      {isOpen && (
        <div className="contact-form">
          <div className="contact-header">
            <h2>Contact Us</h2>
            <button onClick={handleClose} className="cancel-btn">X</button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label>Name:</label>
              <input
                type="text"
                placeholder="Your Name"
                {...register('name', { required: true })}
              />
              {errors.name && <p>Name is required</p>}
            </div>
            <div>
              <label>Phone No:</label> {/* Changed label to Phone Number */}
              <input
                type="tel" // Changed input type to tel
                placeholder="Your Phone Number"
                {...register('phone', { required: true, pattern: /^[0-9]{10}$/ })} // Added pattern for phone number validation
              />
              {errors.phone && <p>Valid phone number is required</p>} {/* Changed error message */}
            </div>
            <div>
              <label>Message:</label>
              <textarea
                placeholder="Your Message"
                {...register('message', { required: true })}
              ></textarea>
              {errors.message && <p>Message is required</p>}
            </div>
            <button className='submit' type="submit">Submit</button>
          </form>
        </div>
      )}
    </>
  );
}

export default ContactForm;
